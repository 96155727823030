import React from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import {
  Wrap,
  Title,
  FormCopy,
  FormBox,
  FormFields,
  FormField,
} from '../components/page/components'
import SEO from '../components/SEO'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import { Link } from 'gatsby'

const AccountBilling = ({ location }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  return (
    <Wrap>
      <SEO title="Account Billing" />
      <FocusHeader
        title="Account"
        subTitle="Billing"
        titleLink="/account"
        backUrl={backUrl}
      />
      <FocusBody>
        <Title>Billing</Title>
        <FormBox>
          <FormFields isProcessing={false}>
            <FormField width={`100%`}>
              <FormCopy>
                Billing management coming soon. Please{' '}
                <Link to="/support">contact us</Link> for billing queries and
                support.
              </FormCopy>
            </FormField>
            <FormField marginRight={`50%`}>
              <FormCopy>
                <p>
                  <Link to="/account">Back</Link>
                </p>
              </FormCopy>
            </FormField>
          </FormFields>
        </FormBox>
      </FocusBody>
    </Wrap>
  )
}

const condition = authUser => !!authUser
const AccountWithAuth = compose(withAuthorization(condition))(AccountBilling)
export default AccountWithAuth
